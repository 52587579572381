import * as d3 from "d3";
import * as cmk_table from "cmk_table";
import * as ntop_utils from "ntop_utils";

export class TopTalkersDashlet extends cmk_table.TableFigure {
    constructor(tabs_bar) {
        super(tabs_bar);
        this._post_url = "ajax_ntop_top_talkers.py";
        this._default_params = {};
        this.scheduler.enable();
        this.scheduler.set_update_interval(60);
    }

    initialize() {
        cmk_table.TableFigure.prototype.initialize.call(this);
        this._div_selection.classed(ntop_utils.ifid_dep, true);
    }

    set_ids(ifid, vlanid = "0") {
        this._ifid = ifid;
        this._vlanid = vlanid;
        this._post_body = new URLSearchParams(
            Object.assign({}, this._default_params, {ifid: this._ifid, vlanid: this._vlanid})
        ).toString();
        this.scheduler.force_update();
    }

    update_data(data) {
        // Add some nice colors to the plot definitions
        let definitions_to_update = [];

        data["rows"].forEach(d =>
            d["cells"].forEach(c =>
                c["figure_config"]
                    ? definitions_to_update.push(c["figure_config"]["plot_definitions"])
                    : null
            )
        );
        definitions_to_update.forEach(entry => {
            entry.forEach((definition, idx) => {
                definition.color = d3.schemePaired[idx];
            });
        });

        cmk_table.TableFigure.prototype.update_data.call(this, data);
    }
}
